module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","url":"https://riotforgegames.com/","site_name":"Riot Forge Games","description":"We're a player- and developer-focused publisher; our mission is to bring awesome new League of Legends games to players by partnering with experienced and ..."},"twitter":{"cardType":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de-de","en-us","ru-ru","es-es","en-gb","en-au","es-ar","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","th-th","tr-tr","zh-tw"],"defaultLanguage":"en-us","generateDefaultLanguagePage":true,"siteUrl":"https://static-staging.rgpub.io/gatsbinger/","redirect":false,"i18nextOptions":{"initImmediate":false,"interpolation":{"escapeValue":false},"ns":["second","first","404"],"defaultNS":"first","load":"currentOnly","keySeparator":".","nsSeparator":":","returnObjects":true,"lowerCaseLng":true,"cleanCode":true,"react":{"useSuspense":true}},"pages":[{"matchPath":"/:lang?/news/:article_slug","getLanguageFromPath":true,"excludeLanguages":["de-de","en-us","ru-ru","es-es","en-gb","en-au","es-ar","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","th-th","tr-tr","zh-tw"]}]},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["de-de","en-us","ru-ru","es-es","en-gb","en-au","es-ar","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","th-th","tr-tr","zh-tw"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K78SL8S","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Riot Forge Games","short_name":"Riot Forge","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/forge_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a4d6eb75192f533306b9aaf735f4f7a3"},
    }]
